<template>
  <div name="RideCard">
    <span class="wrap-text text-center">
      <v-card class="grey lighten-2 ews--text" light>
        <!--       <span class="foo" align="center" justify="center">
 -->
        <v-card-text class="text-h5 black--text">
          <!--           {{ item.who }}
          <br />
 -->
          {{ item.Date }}
          <br />
          <i>{{ item.title }}</i>
          <p></p>

          <div v-for="row in displayIt(item.custom)" v-bind:key="row.name">
            <div v-if="isStatus(row) === confirmID">
              <p>
                <b>{{ row.value }}</b>
              </p>
            </div>
            <div v-else-if="isStatus(row) === true">
              <p>
                <b>
                  <v-chip dark :color="row.color" class="text-h6">
                    {{ row.value }}
                  </v-chip>
                </b>
              </p>
            </div>
            <div v-else-if="row['color'] !== undefined">
              {{ row.name }}:
              <v-chip outlined :color="row.color" class="text-h6">
                {{ row.value }}
              </v-chip>
            </div>

            <div v-else class="text-6">{{ row.name }}: {{ row.value }}</div>
          </div>
        </v-card-text>
      </v-card>
      <v-card color="grey lighten-3" class="desc" light>
        <span v-html="item['notes']" class="wrap-text text-left text-body-1"></span>
      </v-card>
    </span>
  </div>
</template>
<script>
import mixins from "@/components/mixins";
export default {
  name: "RideCard",
  mixins: [mixins],
  data() {
    return {
      st: [],
      bg: "amber accent-4",
      bottomColor: "green",
      statusColors: [],
      status: [],
      confirmID: "",
    };
  },
  props: {
    item: Object,
    statusFieldName: String,
    fieldDefs: String,
  },
  methods: {
    displayIt(custom) {
      var values = [];
      var keys = Object.keys(custom);
      for (var i = 0; i < keys.length; ++i) {
        values.push(this.displayCustom(keys[i], custom));
      }
      var n = Object(values).sort().reverse();
      return Object(n);
    },

    isStatus(row) {
      //            this.convertCustomFields(item);
      //      console.log(this.pp(this.$store.state));
      if (row.id !== this.$store.state.status.id) return false;
      //    if (custom[custom.id] === pendID) return true;
      //      if (this.fuzzyMatch("confirm", row.value)) {
      //        return this.confirmID;
      //      } else return true;
      return true;
    },
    async mounted() {
      await this.getCalData();
      this.confirmID = this.getStatusID("confirm");
    },
  },
};
</script>
<style scoped>
.desc {
  margin-top: 2px;
}
</style>

